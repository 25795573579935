<template>
  <div class="additional-price" data-testid="additionalPrice">
    <bg-text size="title-4" class="mb-16">Informasi Biaya Lain</bg-text>

    <div class="additional-price__head">
      <bg-text data-testid="additionalPriceTitle_lbl"
        >Biaya Tambahan Lain <i>(Jika Ada)</i></bg-text
      >
      <bg-link
        :class="{ 'additional-price__add-button--disabled': disabled }"
        role="button"
        variant="medium"
        @click="addPrice"
      >
        <bg-text :size="disabled ? 'body-4' : 'body-1'">Tambah</bg-text>
      </bg-link>
    </div>

    <div class="additional-price__list">
      <div
        class="additional-price__item"
        v-for="(price, i) in additionalPrices"
        :key="i"
      >
        <add-tenant-field
          label="Nama Biaya"
          :rules="composeRules({ required: !!price.price })"
          :label-for="`additionalPriceName${i}_txt`"
          :data-testid="`additionalPriceName${i}_txt`"
          :immediate="!!price.price"
        >
          <bg-input
            placeholder="Contoh: Listrik"
            v-model="price.label"
            :disabled="isDisabled"
            :id="`additionalPriceName${i}_txt`"
          />
        </add-tenant-field>

        <add-tenant-field
          label="Harga"
          :label-for="`additionalPriceValue${i}_txt`"
          :rules="composeRules({ price_format: true, required: !!price.label })"
          :data-testid="`additionalPriceValue${i}_txt`"
          :immediate="!!price.label"
        >
          <input-currency-masking
            v-model="price.price"
            max-char="14"
            prefix="Rp"
            is-input-only
            :disabled="isDisabled"
            :id="`additionalPriceValue${i}_txt`"
          />
        </add-tenant-field>

        <div class="additional-price__item-action">
          <bg-button-icon
            variant="tertiary-naked"
            icon="delete"
            size="lg"
            :disabled="isDisabled"
            @click="deletePrice(i)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BgText, BgLink, BgInput, BgButtonIcon } from 'bangul-vue';
import InputCurrencyMasking from '@molecules/InputCurrencyMasking';
import AddTenantField from '@/_admin/pages/RoomAllotmentAddTenant/components/AddTenantField';

const PRICE_MODEL = {
  label: '',
  price: '',
};

export default {
  name: 'AddTenantAdditionalPrice',
  components: {
    BgText,
    BgLink,
    BgInput,
    BgButtonIcon,
    InputCurrencyMasking,
    AddTenantField,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      additionalPrices: [...this.prices],
    };
  },
  computed: {
    isDisabled() {
      return this.isReadonly || this.disabled;
    },
  },
  watch: {
    prices: {
      deep: true,
      immediate: true,
      handler(prices) {
        if (prices.length) {
          this.additionalPrices = prices;
        } else {
          this.additionalPrices = [];
          this.addPrice();
        }
      },
    },
    additionalPrices: {
      deep: true,
      handler(prices) {
        this.$emit('update:prices', prices);
      },
    },
  },
  methods: {
    composeRules(rules) {
      return this.isReadonly ? '' : rules;
    },
    addPrice() {
      if (this.disabled && this.additionalPrices.length) return;

      this.additionalPrices.push({ ...PRICE_MODEL });
    },
    deletePrice(priceIndex) {
      if (this.disabled) return;

      if (this.additionalPrices.length > 1) {
        this.additionalPrices.splice(priceIndex, 1);
      } else {
        this.additionalPrices[0].label = '';
        this.additionalPrices[0].price = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantAdditionalPrice.scss"></style>
