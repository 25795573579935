<template>
  <add-tenant-wrapper
    ref="wrapper"
    type-label="Reschedule"
    :listing-id.sync="listingId"
    @property-fetched="property = $event"
  >
    <template #form>
      <add-tenant-information-by-contract
        ref="tenantInformation"
        :listings="listings"
        :listing-id="listingId"
        @contract-changed="handleContractSelected"
      />

      <bg-divider class="add-tenant__divider" />

      <reschedule-payment-section
        :rent-price="totalPrice"
        :rent-type="rentType"
        :rent-duration="rentDuration"
        :room="room"
        :payment-method="paymentMethod"
      >
        <template #checkin-date-field>
          <add-tenant-date-field
            v-model="rescheduleCheckinDate"
            id="checkinDateInput"
            message="Tanggal akan digunakan untuk tanggal penagihan uang sewa"
            label="Tanggal Check-In"
            :disabled="!selectedContract || !isTerminatedContract"
          />
        </template>
      </reschedule-payment-section>

      <bg-divider class="add-tenant__divider" />

      <add-tenant-additional-price
        ref="additionalPrice"
        :prices="additionalPrices"
        disabled
      />

      <bg-button
        variant="primary"
        size="lg"
        class="mt-32"
        :disabled="isDisableSaveButton"
        @click="isShowConfirmationModal = true"
        >Simpan</bg-button
      >
    </template>

    <bg-modal
      v-model="isShowConfirmationModal"
      desktop-size="sm"
      title="Yakin menyimpan data penyewa ini?"
      button-main-text="Setuju"
      button-second-text="Kembali"
      @click-main-action="submitReschedule"
      @click-second-action="isShowConfirmationModal = false"
    >
      <bg-text size="body-2" data-testid="confirmModalDescription">
        Anda akan mengubah tanggal check-in <b>{{ tenantName }}</b> dari tanggal
        <b>{{ initialCheckinDateLabel }}</b> ke
        <b>{{ rescheduleCheckinDateLabel }}</b
        >.
      </bg-text>
    </bg-modal>

    <loading-overlay :value="isSubmitting" />
  </add-tenant-wrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import { BgButton, BgModal, BgText, BgDivider } from 'bangul-vue';

import LoadingOverlay from '@molecules/LoadingOverlay';

import bookingAPI from '@admin_endpoints/booking';
import {
  GENDER_OPTIONS,
  JOB_OPTIONS,
  convertMamipayRentType,
} from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';
import {
  dateFormatterToSend,
  dateFormatterToDisplay,
  dayjs,
  rupiahFormatter,
  cleanNumber,
} from '@/utils/formatter';

import AddTenantWrapper from '../../components/AddTenantWrapper';
import AddTenantInformationByContract from '../../components/AddTenantInformationByContract';
import AddTenantAdditionalPrice from '../../components/AddTenantAdditionalPrice';
import AddTenantDateField from '../../components/fields/AddTenantDateField';

import ReschedulePaymentSection from './components/ReschedulePaymentSection';
import { getCheckoutDateEstimation } from '../../utils/checkoutDate';

export default {
  name: 'AddTenantReschedule',

  components: {
    BgButton,
    BgModal,
    BgText,
    BgDivider,
    LoadingOverlay,
    AddTenantWrapper,
    AddTenantInformationByContract,
    ReschedulePaymentSection,
    AddTenantAdditionalPrice,
    AddTenantDateField,
  },

  data() {
    return {
      GENDER_OPTIONS,
      JOB_OPTIONS,
      listingId: 0,
      property: null,
      selectedContract: null,
      isShowConfirmationModal: false,
      isSubmitting: false,
      rescheduleCheckinDate: null,
    };
  },

  computed: {
    listings() {
      return this.property?.listings || [];
    },
    contractStatus() {
      return this.selectedContract?.status;
    },
    isTerminatedContract() {
      return this.contractStatus === 'terminated';
    },
    tenantName() {
      return this.getTenantValue('name');
    },
    rentPrice() {
      return this.getContractValue('rent_price', 0);
    },
    dpPrice() {
      return this.getContractValue('dp_amount', 0);
    },
    additionalPrices() {
      return this.getContractValue('additional_costs', []).map(cost => ({
        ...cost,
        price: cost?.amount || 0,
        label: cost?.name || '',
      }));
    },
    additionalPricesTotal() {
      return this.additionalPrices.reduce(
        (total, price) => total + (price?.price || 0),
        0
      );
    },
    totalPrice() {
      return this.rentPrice + this.additionalPricesTotal;
    },
    paymentMethod() {
      if (!this.selectedContract) return null;

      const type = this.getContractValue('payment_type', 'full');
      const isDp = type === 'dp';
      const price = isDp ? this.dpPrice : this.totalPrice;
      const typeLabel = isDp ? 'Uang Muka (DP)' : 'Full Payment';
      const label = `${typeLabel} - ${rupiahFormatter(price)}`;

      return {
        label,
        val: type,
      };
    },
    room() {
      return this.getContractValue('room', null);
    },
    rentType() {
      return convertMamipayRentType(this.getContractValue('rent_type'));
    },
    rentDuration() {
      return this.getContractValue('reschedule_duration', 0);
    },
    initialCheckinDateLabel() {
      const initialCheckinDate = this.getContractValue('checkin_date');
      return dateFormatterToDisplay(initialCheckinDate, 'D MMMM YYYY');
    },
    rescheduleCheckinDateLabel() {
      return dateFormatterToDisplay(this.rescheduleCheckinDate, 'D MMMM YYYY');
    },
    hasDifferentCheckinDate() {
      if (this.rescheduleCheckinDateLabel) {
        return this.initialCheckinDateLabel !== this.rescheduleCheckinDateLabel;
      }

      return false;
    },
    isDisableSaveButton() {
      return (
        !this.property ||
        !this.isTerminatedContract ||
        !this.hasDifferentCheckinDate
      );
    },
  },

  methods: {
    ...mapMutations('calendarView', ['setFromSubmitNewBookingState']),

    getContractValue(key, defaultValue = '') {
      return this.selectedContract?.[key] || defaultValue;
    },

    getTenantValue(key, defaultValue = '') {
      return this.selectedContract?.tenant?.[key] || defaultValue;
    },

    handleContractSelected(contract) {
      this.selectedContract = contract || null;
      this.rescheduleCheckinDate =
        dayjs(contract?.checkin_date)?.toDate?.() || null;
      this.contractId = String(contract?.id) || '';
    },

    getRelocationParams() {
      const formattedAdditionalPrice = this.additionalPrices.map(
        ({ label, price }) => ({
          key: label,
          value: cleanNumber(price) || 0,
        })
      );

      const checkinDate = dateFormatterToSend(this.rescheduleCheckinDate);
      const checkoutDate = dateFormatterToSend(
        getCheckoutDateEstimation({
          checkinDate: this.rescheduleCheckinDate,
          duration: this.rentDuration,
          rentCountType: this.rentType,
        })
      );

      return {
        booking_type: 'rescheduled',
        old_contract_id: this.selectedContractId,
        room_id: this.room.id,
        rent_count_type: this.rentType,
        duration: this.rentDuration,
        first_payment_type: this.paymentMethod.val,
        designer_room_id: this.room.unit.id,
        additional_price: formattedAdditionalPrice,
        checkin: checkinDate,
        checkout: checkoutDate,
        ...this.$refs.tenantInformation.getValues(),
      };
    },

    async submitReschedule() {
      this.isSubmitting = true;
      this.isShowConfirmationModal = false;

      try {
        const rescheduleParams = this.getRelocationParams();
        await bookingAPI.postNewBooking(rescheduleParams);

        const rescheduleCheckinDate = dayjs(this.rescheduleCheckinDate);

        const firstDateInStartDateMonth = rescheduleCheckinDate.isValid()
          ? dateFormatterToSend(rescheduleCheckinDate.startOf('month'))
          : undefined;

        this.setFromSubmitNewBookingState(true);
        this.$router.push({
          name: 'room-allotment-calendar-view',
          query: {
            property_id: this.$route.params.propertyId,
            start_date: firstDateInStartDateMonth,
          },
        });
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';
        this.$toast.show(errorMessage);
        this.$error.report(error);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
