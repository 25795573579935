<template>
  <bg-grid>
    <bg-grid-item :col="12">
      <bg-text size="title-4" class="payment-section__title mb-16">
        Informasi Pembayaran
      </bg-text>
    </bg-grid-item>

    <bg-grid-item :col="6">
      <add-tenant-rent-type-field
        :value="rentType"
        :options="RENT_TYPE_OPTIONS"
        disabled
      />
    </bg-grid-item>

    <bg-grid-item :col="6">
      <slot name="checkin-date-field" />
    </bg-grid-item>

    <bg-grid-item :col="6">
      <add-tenant-rent-duration-field
        :value="rentDuration"
        :rent-type="rentType"
        disabled
      />
    </bg-grid-item>

    <bg-grid-item :col="6">
      <bg-field label="Nomor Kamar">
        <bg-select
          options-label-key="name"
          options-value-key="id"
          placeholder="Pilih kamar"
          disabled
          :value="roomUnitId"
          :options="roomOptions"
        />
      </bg-field>
    </bg-grid-item>

    <bg-grid-item :col="6">
      <add-tenant-rent-price-field :value="rentPrice" />
    </bg-grid-item>

    <bg-grid-item :col="6">
      <bg-field label="Metode Pembayaran">
        <bg-select
          :value="paymentMethodType"
          :options="paymentMethodOptions"
          placeholder="Pilih metode pembayaran"
          disabled
        />
      </bg-field>
    </bg-grid-item>
  </bg-grid>
</template>

<script>
import { BgGrid, BgGridItem, BgField, BgSelect, BgText } from 'bangul-vue';
import { RENT_TYPE_OPTIONS } from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';

import AddTenantRentDurationField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentDurationField';
import AddTenantRentPriceField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentPriceField';
import AddTenantRentTypeField from '@admin/pages/RoomAllotmentAddTenant/components/fields/AddTenantRentTypeField';

export default {
  name: 'ReschedulePaymentSection',
  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgText,
    AddTenantRentDurationField,
    AddTenantRentPriceField,
    AddTenantRentTypeField,
  },
  props: {
    rentPrice: {
      type: Number,
      default: 0,
    },
    rentType: {
      type: String,
      default: '',
    },
    rentDuration: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: null,
    },
    paymentMethod: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      RENT_TYPE_OPTIONS,
    };
  },
  computed: {
    roomUnit() {
      return this.room?.unit;
    },
    roomUnitId() {
      return this.roomUnit?.id;
    },
    roomOptions() {
      return this.roomUnit ? [{ ...this.roomUnit }] : [];
    },
    paymentMethodType() {
      return this.paymentMethod?.val || '';
    },
    paymentMethodOptions() {
      return this.paymentMethod ? [{ ...this.paymentMethod }] : [];
    },
  },
};
</script>
